.box {
  background-color: #e6f4ff;
  color: #69b1ff;
  font-weight: bold;
  border-radius: 3px;
  height: 10vh;
  width: 15vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 25px;
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}

.box:hover {
  box-shadow: 2px 5px #69b1ff;
  cursor: pointer;
  top: -10px;
}

@media only screen and (max-width: 800px) {
  .box {
    width: 25vw;
  }
}

@media only screen and (max-width: 500px) {
  .box {
    width: 80%;
  }
}
