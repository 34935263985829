.body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
}
.loading {
  width: 100%;
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}
@media (max-width: 768px) {
  .ant-layout-header {
      padding :0px
  }
}