.body-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.tableConatiner {
  width: 100%;
  max-height: 85vh;
  overflow: scroll;
}
.dropdown-head {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.tableContainerOuterDiv {
  width: 90%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-style: ridge;
  margin: 20px auto;
  border-collapse: collapse;
  max-height: 85vh;
  background: white;
}
.pushChangeBtn {
  background-color: rgb(0, 110, 255) !important;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid rgb(0, 110, 255);
  width: 7rem;
  cursor: pointer;
  height: 35px;
  margin: 10px 0 0 0;
  align-items: center;
}
.pushChangeBtnDisabled {
  background-color: rgb(114, 174, 253);
  color: white;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid rgb(0, 110, 255);
  width: 7rem;
  cursor: pointer;
  height: 35px;
  margin: 10px 0 0 0;
  align-items: center;
}
.pushChangeBtn:hover {
  background-color: rgb(0, 110, 255);
}
.conflictTag {
  width: fit-content !important;
  cursor: pointer;
}

.pushBtnDiv {
  width: 80%;
  display: flex;
  margin: auto;
  justify-content: flex-end;
}

.translationDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#textBoxTranslate {
  height: 30px;
  width: 65%;
  margin-bottom: 10px;
}
.translateOptions {
  height: 30px;
  width: 65%;
  margin-bottom: 10px;
  cursor: pointer;
}
.translationText {
  color: rgb(88, 88, 88);
  width: 65%;
  height: 65px;
  padding: 2px 10px;
  text-align: left;
  border: 1px solid rgb(220, 220, 220);
  overflow-y: auto;
  cursor: pointer;
}
.translationText:hover {
  border: 1px solid black;
  color: black;
}
.translationText::-webkit-scrollbar {
  -webkit-appearance: none;
}

.translationText::-webkit-scrollbar:vertical {
  width: 11px;
}

.translationText::-webkit-scrollbar:horizontal {
  height: 11px;
}
.googleTranslateModal {
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 35vw;
  height: max-content;
}
.languagesdropdown {
  color: white;
  background-color: rgb(0, 110, 255);
  height: 38px;
  margin-top: 10px;
}
.languagesdropdown :hover {
  background-color: white !important;
  color: rgb(0, 110, 255);
  border-radius: 5px;
}

.translationModalBody {
  height: 28vh;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hindi_column {
  background: none !important;
  color: black !important;
  border: none;
}
