.capitalize .ant-select-selector .ant-select-selection-search input {
  text-transform: uppercase;
}

.custom-table-border div {
  border: '1px solid #e1e1e1';
}

.padding-left {
  padding-left: 50px;
}

.container {
  width: 100%;
  padding: 10px;
}

.puja-table {
  margin-top: 10px;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 200%;
}

.puja-table td,
.puja-table th {
  border: 1px solid #4e4646;
  text-align: left;
  padding: 8px;
  /* width:18%; */
}

.puja-table tr:nth-child(even) {
  background-color: #dddddd;
}

.inventory-modal .ant-modal-header {
  background: #eeb711;
}